/* Footer Text */
.footer-text p {
    letter-spacing: 3px;
    font-size: 18px;
    margin-top: 5px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}
  
  .footer-text p:first-child {
    color: #E5B761;
    margin-bottom: 10px;
  }
  
  .footer-text p:last-child {
    color: white;
    margin-top: 2px;
    margin-bottom: 100px;
  }

@media screen and (max-width: 734px) {
    .footer-text p {
        font-size: 16px;
        letter-spacing: 2px;
        margin-top: 20px;
    }

    .footer-text p:last-child {
        padding: 0 100px;
        margin-bottom: 60px;
    }
}

@media screen and (max-width: 733px) {
    .footer-text p {
        font-size: 14px;
        letter-spacing: 1.5px;
        margin-top: 15px;
    }

    .footer-text p:last-child {
        padding: 0 50px;
        margin-bottom: 40px;
    }
}

.footer-social {
  margin-top: 1rem;
  display: flex;
  gap: 2rem;
  justify-content: center;
}

.footer-social a {
  color: #fff;
  transition: color 0.3s ease;
}

.footer-social a:hover {
  color: #888;
}