@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --scroll: 0;
  --font-family: 'Montserrat', sans-serif;

  
  --color-bg: #ffffff;
  --color-footer : #031B34;
  --color-blog: #0E5A8E;
  --color-text: #0E5A8E;
  --color-subtext: #d54f11;
}