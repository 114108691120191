/* Tabs Container */
.tabs-container {
  width: 100%;
  position: relative;
  z-index: 1; /* Set z-index higher than .learn to ensure tabs are clickable */
  padding: 0 20px;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 100px;
  box-sizing: border-box;
}

/* Tabs */
.tabs {
  display: flex;
  align-items: stretch;
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  background-color: #000000ef; /* Match App background */
  border-radius: 20px 20px 0 0;
  overflow: hidden;
  box-sizing: border-box;
}

.tab {
  font-size: 20px;
  font-weight: 650;
  flex: 1;
  padding: 12px;
  border: 1px solid #A29B99;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
  background-color: #000;
  font-family: 'Montserrat', sans-serif;

  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  transition: background-color 0.3s, color 0.3s; /* Smooth transition */
}

.tab:first-child {
  border-top-left-radius: 20px;
}

.tab:last-child {
  border-top-right-radius: 20px;
}

.tab:hover {
  color: #E5B761; /* Highlight on hover */
  background-color: #333; /* Slight background change on hover */
}

.tab.active {
  background-color: #E5B761;
  color: #000;
}

/* Contents */
.contents {
  width: 80%;
  margin: 0 auto;
  background-color: #000000ef; /* Match App background */
  border-radius: 0 0 20px 20px;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
}

/* Content */
.content {
  padding: 30px;
  height: auto;
  min-height: 200px;
  background-color: #000;
  background-size: cover;
  background-position: center;
  opacity: 1;
  border: 1px solid #A29B99;
  display: none;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-sizing: border-box;
}

.content.active {
  display: block;
}

.content h3 {
  margin-top: 0;
  margin-bottom: -5px;
  font-weight: 700;
  color: #E5B761;
  letter-spacing: 7px;
  font-size: 30px;
  opacity: 1;
}

.content p {
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 1.6;
  opacity: 1;
}

/* Section Heading */
.learn {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  letter-spacing: 10px;
  font-size: 40px;
  position: relative;
  z-index: 0;
  margin-top: 300px;
  margin-bottom: -20px;
  text-align: center; /* Centered text */
}

/* Responsive Media Queries */
@media screen and (max-width: 734px) {
  .tab {
    font-size: 16px;
    padding: 10px 0;
  }

  .content-text h3 {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .content-text p {
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 15px;
  }

  .tabs {
    width: 90%; /* Slightly wider on mobile */
  }

  .tabs-container {
    padding: 0 15px;
    margin: 20px 0;
  }

  .content {
    padding: 25px;
  }

  .content h3 {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 733px) {
  .tab {
    font-size: 14px;
    padding: 8px 0;
  }

  .content-text h3 {
    font-size: 16px;
    margin-bottom: 6px;
  }

  .content-text p {
    font-size: 13px;
    line-height: 1.3;
    margin-bottom: 12px;
  }

  .tabs {
    width: 100%;
  }

  .tabs-container {
    padding: 0 10px;
    margin: 15px 0;
  }

  .content {
    padding: 20px;
  }

  .content h3 {
    margin-bottom: 5px;
  }

  .learn {
    margin-top: 100px;
    margin-bottom: -40px;
    font-size: 24px;
    font-weight: 350;
    text-align: center; /* Centered text */
  }
}
