.game {
    text-align: center;
    color: white;
    transform: translateY(-20px);
    margin-bottom: -50px;
  }
  
  .game h2 {
    font-size: 25px;
    font-weight: 700;
    color: #E5B761;
    letter-spacing: 10px;
    margin-bottom: 10px;
  }
  
  .game p {
    font-size: 25px;
    letter-spacing: 2px;
  }

  @media screen and (max-width: 734px) {
    .header {
      font-size: 3.5rem;
      padding: 40px 10px 20px;
    }
    
    .header h2 {
      font-size: 20px;
      margin-top: 25px;
      margin-bottom: 30px;
    }

  }

  @media screen and (max-width: 733px) {
    .game {
      text-align: left;
    }
    .header {
      font-size: 4.5rem;
      padding: 30px 1px 15px;
    }
    
    .header h2 {
      font-size: 30px;
      margin-top: -10px;
      margin-bottom: 10px;
      letter-spacing: 3px;
    }

  }