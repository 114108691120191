.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 2rem 6rem;
}

.navbar-links {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}


.navbar-links_logo img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.navbar-links_container {
  display: flex;
  flex-direction: row;
}

.navbar-sign {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}


.navbar-sign button {
  position: relative;
  -webkit-tap-highlight-color: transparent;
  padding: 10px 20px;
  background-color: #E5B761;
  font-family: inherit;
  font-weight: 900;
  font-size: 18px;
  line-height: 1.5;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 15px;
  overflow: hidden;
  transition: transform 0.2s ease-in-out;
}

.navbar-sign button a {
  color: black;
  text-decoration: none;
  position: relative;
  z-index: 2;
  font-weight: 900;
}

.navbar-sign button:disabled {
  cursor: default;
}

.navbar-sign button:hover {
  transform: scale(1.1);
}

.navbar-sign button:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 0;
  aspect-ratio: 1;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.2s ease-in-out;
  z-index: 1;
}

.navbar-sign button:hover:before {
  width: 100%;
}

.navbar-sign button:-moz-focusring {
  outline: auto;
}

.navbar-menu {
  margin-left: 1rem;

  display: none;
  position: relative;
}

.navbar-menu svg {
  cursor: pointer;
}

.navbar-menu_container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  
  text-align: right;
  background: var(--color-footer);
  padding-right: 1rem;
  padding-bottom: 0.5rem;
  position: absolute;
  right: 0;
  top: 40px;
  margin-top: 1rem;
  min-width: 135px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);
}

.navbar-menu_container p {
  margin: 1rem 0;
}

.navbar-menu_container-links-sign {
  display: none;
}

@media screen and (max-width: 734px) {
  .navbar-links_container {
      display: none;
  }
  .navbar-menu {
      display: flex;
      padding-bottom: 5.5rem;
  }
  .navbar-links_logo img {
    width: 100px;
    height: 100px;
  }
  .navbar-sign button {
    padding: 6px 12px;
    font-size: 14px;
  }
}

@media screen and (max-width: 733px) {
  .navbar {
      padding: 2rem;
  }
  .navbar-links_logo img {
    width: 100px;
    height: 100px;
  }
  .navbar-sign button {
    padding: 10px 20px;
    font-size: 16px;
  }
}
