.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    padding: 60px 10px 30px;
    letter-spacing: 10px;
    font-size: 5.5rem;
    position: relative;
    margin-top: -20px;
    font-weight: 700;
  }
  
  .header h2 {
    font-size: 40px;
    margin-top: -10px;
    margin-bottom: 20px;
    font-weight: normal;
    letter-spacing: 5px;
  }


  @media screen and (max-width: 734px) {
    .header {
      font-size: 3.5rem;
      padding: 40px 10px 20px;
    }
    
    .header h2 {
      font-size: 20px;
      margin-top: 25px;
      margin-bottom: 30px;
    }

  }

  @media screen and (max-width: 733px) {
    .header {
      font-size: 4.5rem;
      padding: 30px 1px 15px;
    }
    
    .header h2 {
      font-size: 30px;
      margin-top: -10px;
      margin-bottom: 10px;
      letter-spacing: 3px;
    }

  }