/* New Gallery Styles */
.gallery-wrap {
  width: 100%;
  overflow: hidden;
  position: relative;
  margin: 2.5% auto;
}

.gallery {
  display: flex;
  animation: slideRight 40s linear infinite;
  width: fit-content;
}

.gallery-group {
  display: flex;
  gap: 40px;
  padding: 0 20px;
}

.gallery-item {
  width: 308px;
  height: 400px;
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
}

@keyframes slideRight {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%);
  }
}

/* Individual gallery items */
.mag1 { background-image: url('../../assets/Derek.png'); }
.mag2 { background-image: url('../../assets/tiger.png'); }
.mag3 { background-image: url('../../assets/jordan.png'); }
.mag4 { background-image: url('../../assets/Brady.png'); }
.mag5 { background-image: url('../../assets/woman.png'); }
.mag6 { background-image: url('../../assets/Wayne.png'); }
.mag7 { background-image: url('../../assets/shitlaxplayer.png'); }
.mag8 { background-image: url('../../assets/phelps.png'); }
