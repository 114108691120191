/* Footer Section */
.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #ffffff;
    background-color: #000000ef; 
    padding: 50px 20px;
    border-radius: 20px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    margin-top: 100px; 
  }
  
  .footer h2 {
    font-size: 36px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 7px;
    margin-bottom: 20px;
    font-family: 'Montserrat', sans-serif;
  }
  
  .footer-divider {
    width: 60%;
    height: 2px;
    background-color: #A29B99;
    border: none;
    margin: 20px 0;
  }
  
  .footer button {
    position: relative;
    -webkit-tap-highlight-color: transparent;
    padding: 10px 20px;
    background-color: #E5B761;
    font-family: inherit;
    font-weight: 900;
    font-size: 18px;
    line-height: 1.5;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 15px;
    overflow: hidden;
    transition: transform 0.2s ease-in-out;
  }
  
  .footer button a {
    color: black;
    text-decoration: none;
    position: relative;
    z-index: 2;
    font-weight: 900;
  }

  .footer button:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 0;
    aspect-ratio: 1;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transition: width 0.2s ease-in-out;
    z-index: 1;
  }
  
  .footer button:hover:before {
    width: 100%;
  }
  
  .footer button:hover {
    transform: scale(1.1);
  }
  
  .footer button:disabled {
    cursor: default;
  }
  
  .footer button:-moz-focusring {
    outline: auto;
  }
  
  .footer button a:hover {
    color: #000000; 
  }
  
  @media screen and (max-width: 734px) {
    .footer h2 {
      font-size: 28px;
      letter-spacing: 5px;
    }
  
    .footer-divider {
      width: 80%;
    }
  
    .footer button {
      font-size: 18px;
      padding: 10px 20px;
    }
  
    .footer button a {
      padding: 10px 20px; 
    }
  }
  
  @media screen and (max-width: 733px) {
    .footer h2 {
      font-size: 24px;
      letter-spacing: 4px;
    }
  
    .footer button {
      font-size: 16px;
      padding: 8px 18px;
    }
  
    .footer button a {
      padding: 8px 18px; 
    }
  }